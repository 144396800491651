import logo from './logo.svg';
import './App.css';
import {Route, BrowserRouter, Switch} from 'react-router-dom'

import HomeView from './views/HomeView'

function App() {
  return (
    <BrowserRouter>
      <Switch>
          <Route exact path="/" render={ () => <HomeView/>}></Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
