import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";
        
        
//import photo from '/image/13662192_1218346884851677_8269900785789147208_o.jpj';

const useStyles = makeStyles({
    uprightLogo : {
        width: "100%",
    },
});

function HomeView() {

    const classes = useStyles();
    
    return (
        /*<div>
            <li className="image-element-class">
            <img src="/image/13662192_1218346884851677_8269900785789147208_o.jpg" alt="img"/>
            </li>
        </div>*/
        <div className={`container-fluid`}>
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Emma Flowers</title>
                </Helmet>
            </div>
            <div class="row">
            {/* Left sidebar */}
            <div id="tm-sidebar" class="tm-sidebar"> 
                <nav class="tm-nav">
                    <button class="navbar-toggler" type="button" aria-label="Toggle navigation">
                        <i className="fas fa-bars"></i>
                    </button>
                    <div>
                        <div class="tm-brand-box">
                            {/**<h1 class="tm-brand">Upright</h1>*/}
                            <img className={classes.uprightLogo} src="image/sys/white_logo_color_background.jpg" alt=""/>
                        </div>                
                        <ul id="tm-main-nav">
                            <li class="nav-item">                                
                                <a href="#home" class="nav-link current">
                                    <div class="triangle-right"></div>
                                    <i class="fas fa-home nav-icon"></i>
                                    Home
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#gallery" class="nav-link">
                                    <div class="triangle-right"></div>
                                    <i class="fas fa-images nav-icon"></i>
                                    Gallery
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#about" class="nav-link">
                                    <div class="triangle-right"></div>
                                    <i class="fas fa-user-friends nav-icon"></i>
                                    About
                                </a>
                            </li>
                            {/*<li class="nav-item">
                                <a href="#contact" class="nav-link">
                                    <div class="triangle-right"></div>
                                    <i class="fas fa-envelope nav-icon"></i>
                                    Contact
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="https://paypal.me/templatemo" class="nav-link external" target="_parent" rel="sponsored">
                                    <div class="triangle-right"></div>
                                    <i class="fas fa-external-link-alt nav-icon"></i>
                                    External
                                </a>
                            </li>*/}
                        </ul>
                    </div>
                    {/*<footer class="mb-3 tm-mt-100">
                        Design: <a href="https://templatemo.com" target="_parent" rel="sponsored">TemplateMo</a>
                    </footer>*/}
                </nav>
            </div>
            
            <div class="tm-main">
                {/* Home section */}
                <div class="tm-section-wrap">
                    <div class="tm-parallax" data-parallax="scroll" data-image-src="img/img-01.jpg"></div>
                    {/*<section id="home" class="tm-section">*/}                
                    <section id="homes" class="tm-section">
                        <h2 class="tm-text-primary">EmmaFlowers</h2>
                        <hr class="mb-5"/>
                        {/*<div class="row">
                            <div class="col-lg-6 tm-col-home mb-4">
                                <div class="tm-text-container">
                                    <div class="tm-icon-container mb-5 mr-0 ml-auto">
                                        <i class="fas fa-satellite-dish fa-4x tm-text-primary"></i>
                                    </div>                                
                                    <p>
                                        Leftmost column is placed for logo and main menu.
                                        After that is an image column. Right side column 
                                        is a 100% full-width content.
                                    </p>
                                    <p>
                                        Right side can put many contents and it will
                                        scroll up / down. Left side is fixed. Parallax
                                        Image changes for different pages.
                                    </p>
                                </div>                                
                            </div>
                            <div class="col-lg-6 tm-col-home mb-4">
                                <div class="tm-text-container">
                                    <div class="tm-icon-container mb-5 mr-0 ml-auto">
                                        <i class="fas fa-broadcast-tower fa-4x tm-text-primary"></i>
                                    </div>                                 
                                    <p>
                                        Quisque tincidunt, sem rutrum euismod ornare, tortor arcu tempus 
                                        lorem, accumsan suscipit mauris lorem at lorem. Praesent feugiat 
                                        mi at tortor tincidunt, ac consequat ante cursus.
                                    </p>
                                    <div class="text-right">
                                        <a href="#gallery" class="btn btn-primary tm-btn-next">Next Page</a>
                                    </div>                                    
                                </div>
                            </div>
                        </div>*/}
                        <hr class="tm-hr-short mb-5"/>
                        {/*<div class="row tm-row-home">*/}                            
                            {/*<div class="tm-col-home tm-col-home-l">*/}
                                <div class="media mb-4">
                                    <i class="fas fa-scroll fa-4x tm-post-icon tm-text-primary"></i>
                                    <div class="media-body">
                                        <a href="#" class="d-block mb-2 tm-text-primary tm-post-link">Agenda 2022</a>  
                                        <p>Upcoming events </p>
                                    </div>                                    
                                </div>
                                {/*<div class="media mb-4">
                                    <i class="fas fa-cloud-sun fa-4x tm-post-icon tm-text-primary"></i>
                                    <div class="media-body">
                                        <a href="#" class="d-block mb-2 tm-text-primary tm-post-link">22 September 2020</a>  
                                        <p>
                                            You are not allowed to re-distribute this template as a ZIP file 
                                            on any template collection website for the template download purpose. 
                                            Please contact us for more information.
                                        </p>
                                    </div>                                    
                                </div>
                                <div class="media mb-4">
                                    <i class="fas fa-dove fa-4x tm-post-icon tm-text-primary"></i>
                                    <div class="media-body">
                                        <a href="#" class="d-block mb-2 tm-text-primary tm-post-link">16 September 2020</a>  
                                        <p>
                                            You may want to support us by making   <a href="https://paypal.me/templatemo" target="_parent" rel="sponsored">a small donation via PayPal</a>. That will be helpful. We hope you like this Upright Template for your work.
                                        </p>
                                    </div>                                    
                                </div>*/}
                            {/*</div>*/}
                            {/*<div class="tm-col-home mr-0 ml-auto">
                                <div class="tm-img-home-container"></div>                                
                            </div>*/}
                        {/*</div>*/}
                    </section>
                </div>
                {/* Gallery Section */}
                <div class="tm-section-wrap">
                    <div class="tm-parallax" data-parallax="scroll" data-image-src="img/img-02.jpg"></div>                   
                    <section id="gallery" class="tm-section">
                        <h2 class="tm-text-primary">Gallery</h2>
                        <hr class="mb-5"/>
                        <ul class="tm-gallery-links">
                            <li>
                                <a href="javascript:void(0);" class="active tm-gallery-link" data-filter="*">
                                    <i class="fas fa-layer-group tm-gallery-link-icon"></i>
                                    All
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="tm-gallery-link" data-filter="portrait">
                                    <i class="fas fa-portrait tm-gallery-link-icon"></i>
                                    Portraits/Arts
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="tm-gallery-link" data-filter="nature">
                                    <i class="fas fa-leaf tm-gallery-link-icon"></i>
                                    Nature
                                </a>
                            </li>
                            {/*<li>
                                <a href="javascript:void(0);" class="tm-gallery-link" data-filter="animal">
                                    <i class="fas fa-paw tm-gallery-link-icon"></i>
                                    Animals
                                </a>
                            </li>*/}
                            <li>
                                <a href="javascript:void(0);" class="tm-gallery-link" data-filter="building">
                                    <i class="far fa-building tm-gallery-link-icon"></i>
                                    Buildings
                                </a>
                            </li>
                        </ul>
                        <div class="tm-gallery">
                            
                            <figure class="effect-honey tm-gallery-item building">
                                <img src="image/gallery/building-01.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/building-01.jpg">View more</a>						
                                </figcaption>			
                            </figure>  
                            <figure class="effect-honey tm-gallery-item nature">
                                <img src="image/gallery/nature-01.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/nature-01.jpg">View more</a>						
                                </figcaption>			
                            </figure>
                    
                            <figure class="effect-honey tm-gallery-item building">
                                <img src="image/gallery/building-02.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/building-02.jpg">View more</a>						
                                </figcaption>			
                            </figure>
                            <figure class="effect-honey tm-gallery-item nature">
                                <img src="image/gallery/nature-02.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/nature-02.jpg">View more</a>						
                                </figcaption>			
                            </figure> 
                            
                            
                            <figure class="effect-honey tm-gallery-item building">
                                <img src="image/gallery/building-03.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/building-03.jpg">View more</a>						
                                </figcaption>			
                            </figure>
                            

                            <figure class="effect-honey tm-gallery-item nature">
                                <img src="image/gallery/nature-03.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/nature-03.jpg">View more</a>						
                                </figcaption>			
                            </figure>
                            
                            <figure class="effect-honey tm-gallery-item building">
                                <img src="image/gallery/building-04.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="img/gallery/building-04.jpg">View more</a>						
                                </figcaption>			
                            </figure>
                            <figure class="effect-honey tm-gallery-item nature">
                                <img src="image/gallery/nature-04.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/nature-04.jpg">View more</a>						
                                </figcaption>			
                            </figure> 
                            
                            
                            <figure class="effect-honey tm-gallery-item building">
                                <img src="image/gallery/building-05.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/building-05.jpg">View more</a>						
                                </figcaption>			
                            </figure>
                            
                            <figure class="effect-honey tm-gallery-item nature">
                                <img src="image/gallery/nature-05.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/nature-05.jpg">View more</a>						
                                </figcaption>			
                            </figure>  
                            <figure class="effect-honey tm-gallery-item nature">
                                <img src="image/gallery/nature-06.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/nature-06.jpg">View more</a>						
                                </figcaption>			
                            </figure>
                            
                            <figure class="effect-honey tm-gallery-item building">
                                <img src="image/gallery/building-06.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/building-06.jpg">View more</a>						
                                </figcaption>			
                            </figure>
                            
                            <figure class="effect-honey tm-gallery-item nature">
                                <img src="image/gallery/nature-07.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/nature-07.jpg">View more</a>						
                                </figcaption>			
                            </figure> 
                            
                            
                            <figure class="effect-honey tm-gallery-item building">
                                <img src="image/gallery/building-07.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/building-07.jpg">View more</a>						
                                </figcaption>			
                            </figure>
                            
                            <figure class="effect-honey tm-gallery-item building">
                                <img src="image/gallery/building-08.jpg" alt=""/>
                                <figcaption>
                                    <h2><i>Emma</i></h2>
                                    <a href="image/gallery/building-08.jpg">View more</a>						
                                </figcaption>			
                            </figure>  
                            
                        </div>
                    </section>
                </div>
                {/* About Section */}
                <div class="tm-section-wrap">
                    <div class="tm-parallax" data-parallax="scroll" data-image-src="img/img-03.jpg"></div>
                    <section id="about" class="tm-section">
                        <h2 class="tm-text-primary">About Emma</h2>
                        <hr class="mb-5"/>
                        
                        <div class="row tm-row-about">
                            <div class="tm-col-about tm-col-about-l">
                                <p class="mb-4">
                            Emma is an art enthusiast, traveller, and loves plants and gardening. Thank you. </p>
                                <p class="mb-4"> 
                                </p>
                                <p class="mb-4">
                                    If you are interested in travelling the world and gardening hopefully you will find my content inspiring.
                                </p>
                            </div>
                            {/*<div class="tm-col-about tm-col-about-r">
                                <img src="img/img-about-01.jpg" alt="Image" class="img-fluid mb-5 tm-img-about"/>
                                <p>
                                    Duis fringilla eget purus luctus sodales. Sed auctor odio quis ligula dignissim efficitur vitae vitae quam.
                                </p>
                            </div>*/}
                        </div>
                        {/*<div class="mt-5">
                            <div class="tm-carousel">
                                <div class="tm-carousel-item">
                                    <figure class="effect-honey mb-4">
                                        <img src="img/about-01.jpg" alt="Featured Item"/>
                                        <figcaption>
                                            <ul class="tm-social">
                                                <li><a href="https://facebook.com" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </figcaption>
                                    </figure>
                                    <div class="tm-about-text">
                                        <h3 class="mb-3 tm-text-primary tm-about-title">Calvin Walk</h3>
                                        <p>Aliquam efficitur, velit ut aliquet molestie, nulla dolor faucibus neque, quis hendrerit.</p>
                                        <h4 class="tm-text-secondary tm-about-subtitle">Founder and CEO</h4>
                                    </div>
                                </div>
                                
                                <div class="tm-carousel-item">
                                    <figure class="effect-honey mb-4">
                                        <img src="img/about-02.jpg" alt="Featured Item"/>
                                        <figcaption>
                                            <ul class="tm-social">
                                                <li><a href="https://facebook.com" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </figcaption>
                                    </figure>
                                    <div class="tm-about-text">
                                        <h3 class="mb-3 tm-text-primary tm-about-title">Jenifer Soft</h3>
                                        <p>This is a carousel for a list of 10 team members. Each member image hover has 3 social icons.</p>
                                        <h4 class="tm-text-secondary tm-about-subtitle">Managing Director</h4>
                                    </div>
                                </div>

                                <div class="tm-carousel-item">
                                    <figure class="effect-honey mb-4">
                                        <img src="img/about-03.jpg" alt="Featured Item"/>
                                        <figcaption>
                                            <ul class="tm-social">
                                                <li><a href="https://facebook.com" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </figcaption>
                                    </figure>
                                    <div class="tm-about-text">
                                        <h3 class="mb-3 tm-text-primary tm-about-title">David Rock</h3>
                                        <p>Aliquam efficitur, velit ut aliquet molestie, nulla dolor faucibus neque, quis hendrerit.</p>
                                        <h4 class="tm-text-secondary tm-about-subtitle">Financial Officer</h4>
                                    </div>
                                </div>

                                <div class="tm-carousel-item">
                                    <figure class="effect-honey mb-4">
                                        <img src="img/about-01.jpg" alt="Featured Item"/>
                                        <figcaption>
                                            <ul class="tm-social">
                                                <li><a href="https://facebook.com" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </figcaption>
                                    </figure>
                                    <div class="tm-about-text">
                                        <h3 class="mb-3 tm-text-primary tm-about-title">Calvin Second</h3>
                                        <p>Aliquam efficitur, velit ut aliquet molestie, nulla dolor faucibus neque, quis hendrerit.</p>
                                        <h4 class="tm-text-secondary tm-about-subtitle">Senior Director</h4>
                                    </div>
                                </div>
                                
                                <div class="tm-carousel-item">
                                    <figure class="effect-honey mb-4">
                                        <img src="img/about-02.jpg" alt="Featured Item"/>
                                        <figcaption>
                                            <ul class="tm-social">
                                                <li><a href="https://facebook.com" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </figcaption>
                                    </figure>
                                    <div class="tm-about-text">
                                        <h3 class="mb-3 tm-text-primary tm-about-title">Jenifer Second</h3>
                                        <p>This is a carousel for a list of 10 team members. Each member image hover has 3 social icons.</p>
                                        <h4 class="tm-text-secondary tm-about-subtitle">Accounting Officer</h4>
                                    </div>
                                </div>

                                <div class="tm-carousel-item">
                                    <figure class="effect-honey mb-4">
                                        <img src="img/about-03.jpg" alt="Featured Item"/>
                                        <figcaption>
                                            <ul class="tm-social">
                                                <li><a href="https://facebook.com" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </figcaption>
                                    </figure>
                                    <div class="tm-about-text">
                                        <h3 class="mb-3 tm-text-primary tm-about-title">David Second</h3>
                                        <p>Aliquam efficitur, velit ut aliquet molestie, nulla dolor faucibus neque, quis hendrerit.</p>
                                        <h4 class="tm-text-secondary tm-about-subtitle">Financial Assistant</h4>
                                    </div>
                                </div>

                                <div class="tm-carousel-item">
                                    <figure class="effect-honey mb-4">
                                        <img src="img/about-01.jpg" alt="Featured Item"/>
                                        <figcaption>
                                            <ul class="tm-social">
                                                <li><a href="https://facebook.com" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </figcaption>
                                    </figure>
                                    <div class="tm-about-text">
                                        <h3 class="mb-3 tm-text-primary tm-about-title">Calvin Third</h3>
                                        <p>Aliquam efficitur, velit ut aliquet molestie, nulla dolor faucibus neque, quis hendrerit.</p>
                                        <h4 class="tm-text-secondary tm-about-subtitle">Designer</h4>
                                    </div>
                                </div>
                                
                                <div class="tm-carousel-item">
                                    <figure class="effect-honey mb-4">
                                        <img src="img/about-02.jpg" alt="Featured Item"/>
                                        <figcaption>
                                            <ul class="tm-social">
                                                <li><a href="https://facebook.com" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </figcaption>
                                    </figure>
                                    <div class="tm-about-text">
                                        <h3 class="mb-3 tm-text-primary tm-about-title">Jenifer Third</h3>
                                        <p>This is a carousel for a list of 10 team members. Each member image hover has 3 social icons.</p>
                                        <h4 class="tm-text-secondary tm-about-subtitle">Program Director</h4>
                                    </div>
                                </div>

                                <div class="tm-carousel-item">
                                    <figure class="effect-honey mb-4">
                                        <img src="img/about-03.jpg" alt="Featured Item"/>
                                        <figcaption>
                                            <ul class="tm-social">
                                                <li><a href="https://facebook.com" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </figcaption>
                                    </figure>
                                    <div class="tm-about-text">
                                        <h3 class="mb-3 tm-text-primary tm-about-title">David Three</h3>
                                        <p>Aliquam efficitur, velit ut aliquet molestie, nulla dolor faucibus neque, quis hendrerit.</p>
                                        <h4 class="tm-text-secondary tm-about-subtitle">Financial Assistant</h4>
                                    </div>
                                </div>

                                <div class="tm-carousel-item">
                                    <figure class="effect-honey mb-4">
                                        <img src="img/about-01.jpg" alt="Featured Item"/>
                                        <figcaption>
                                            <ul class="tm-social">
                                                <li><a href="https://facebook.com" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                                <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </figcaption>
                                    </figure>
                                    <div class="tm-about-text">
                                        <h3 class="mb-3 tm-text-primary tm-about-title">Calvin Four</h3>
                                        <p>Aliquam efficitur, velit ut aliquet molestie, nulla dolor faucibus neque, quis hendrerit.</p>
                                        <h4 class="tm-text-secondary tm-about-subtitle">Media Partner</h4>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                    </section>
                </div>

                {/* Contact Section */}
                {/*<div class="tm-section-wrap">
                    <div class="tm-parallax" data-parallax="scroll" data-image-src="img/img-04.jpg"></div>
                    <div id="contact" class="tm-section">
                        <h2 class="tm-text-primary">Contact Upright</h2>
                        <hr class="mb-5"/>
                        <div class="row">
                            <div class="col-xl-6 tm-contact-col-l mb-4">
                                <form id="contact-form" action="" method="POST" class="tm-contact-form">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control rounded-0" placeholder="Name" required />
                                    </div>
                                    <div class="form-group">
                                        <input type="email" name="email" class="form-control rounded-0" placeholder="Email" required />
                                    </div>
                                    <div class="form-group">
                                        <select class="form-control" id="contact-select" name="inquiry">
                                            <option value="-">Subject</option>
                                            <option value="sales">Sales &amp; Marketing</option>
                                            <option value="creative">Creative Design</option>
                                            <option value="uiux">UI / UX</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <textarea rows="8" name="message" class="form-control rounded-0" placeholder="Message" required="true"></textarea>
                                    </div>
            
                                    <div class="form-group tm-text-right">
                                        <button type="submit" class="btn btn-primary">Send</button>
                                    </div>
                                </form>
                            </div>
                            <div class="col-xl-6 tm-contact-col-r">
                                {/* Map 
                                <div class="mapouter mb-4">
                                    <div class="gmap_canvas">
                                        <iframe width="100%" height="520" id="gmap_canvas"
                                            src="https://maps.google.com/maps?q=Av.+L%C3%BAcio+Costa,+Rio+de+Janeiro+-+RJ,+Brazil&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                            frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                                            title="maps"
                                        >
                                        </iframe>
                                    </div>
                                </div>
            
                                {/* Address 
                                <address class="mb-4">
                                    120-240 eget purus luctus sodales. Sed<br/>
                                    auctor odio quis ligula dignissim efficitur<br/>
                                    vitae vitae quam 16502
                                </address>
            
                                {/* Links 
                                <ul class="tm-contact-links mb-4">
                                    <li class="mb-2">
                                        <a href="tel:0100200340">
                                            <i class="fas fa-phone mr-2 tm-contact-link-icon"></i>
                                            Tel: 010-020-0340
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:info@company.com">
                                            <i class="fas fa-at mr-2 tm-contact-link-icon"></i>
                                            Email: info@company.com
                                        </a>
                                    </li>
                                </ul>
                                <ul class="tm-contact-social">
                                    <li><a href="https://fb.com/templatemo" class="tm-social-link"><i class="fab fa-facebook"></i></a></li>
                                    <li><a href="https://twitter.com" class="tm-social-link"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://instagram.com" class="tm-social-link"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="https://youtube.com" class="tm-social-link"><i class="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>*/}

                {/* Copyright */}               
                {/*<div class="tm-section-wrap tm-copyright row">
                    <div class="col-12">
                        <div class="text-right">
                            Copyright 2020 Upright Company
                        </div> 
                    </div>
            </div>*/}                 
            </div> {/* .tm-main */}                     
        </div> {/* row */}
        </div>
        
    );
}

export default HomeView;